import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({ 
  providedIn: 'root'
})

export class CommonapiService {
  apiURL: string = environment.base_url;
  BaseURL: string = "https://www.planetido.com/idoapi/"; 
  webURL : string = "https://www.planetido.com/#/share_userprofilepage/"
  private _username: string = 'lmx@winbull';
  private _password: string = 'lmx@2018';
  public UserName: string; 
  public isLoading = new BehaviorSubject(false);
  public newUserDetails: any = {};
  public currentUSerDetails: any = {};
  
     //Account Settings
    public account_settings             = new BehaviorSubject<Object>(Object);   
  
     // User Profile Edits
	 
	public edit_user_profile          	 = new BehaviorSubject<Object>(Object); 
	
	public user_records                  = new BehaviorSubject<Object>(Object);

	public user_personal_detalis         = new BehaviorSubject<Object>(Object);

	public user_eventrecords             = new BehaviorSubject<Object>(Object);

	public main_eventrecords             = new BehaviorSubject<Object>(Object);

	public sub_eventrecords              = new BehaviorSubject<Object>(Object);
	
	public user_subeventrecords          = new BehaviorSubject<Object>(Object);
	
	// Other User Viewed detalis
	
	public other_user_detalis            = new BehaviorSubject<Object>(Object);
	
	// Event_Records in BehaviorSubject
	
    public events_personal_records       = new BehaviorSubject<Object>(Object);
	
    public events_date_records           = new BehaviorSubject<Object>(Object);
	
    public subevents_view_records        = new BehaviorSubject<Object>(Object);
	
	public user_invited_event    	     = new BehaviorSubject<Object>(Object);
	
	public img_upload_type               = new BehaviorSubject<Object>(Object);
	
	public events_jointme_records        = new BehaviorSubject<Object>(Object);
	
	public events_listing_screen    	 = new BehaviorSubject<Object>(Object);
	
	public events_listing_defscreen    	 = new BehaviorSubject<Object>(Object);
	
	public events_received_classfication = new BehaviorSubject<Object>(Object);
	
	public events_received_notifications_records = new BehaviorSubject<Object>(Object);
	
	public events_received_classfication1 = new BehaviorSubject<Object>(Object);
	
	// Event Shareing Options
	
	public share_allevents               = new BehaviorSubject<Object>(Object);
	
	public share_subevent_source         = new BehaviorSubject<Object>(Object);
	
	public shared_subevents_source       = new BehaviorSubject<Object>(Object);
	
		
	public share_events_records          =  new BehaviorSubject<Object>(Object);
	
	
	// Restore Datas
	
	public restore_userdetalis           = new BehaviorSubject<Object>(Object);
	
	public restore_recycledata           = new BehaviorSubject<Object>(Object);
	
	public restored_datas                = new BehaviorSubject<Object>(Object);
	
	public recycle_listing_screen        = new BehaviorSubject<Object>(Object);
	
	public recycle_listing_defscreen     = new BehaviorSubject<Object>(Object);
	
	// Chat Conversation Datas
	
	public chatconversation_userdetalis  = new BehaviorSubject<Object>(Object);

   // Contatcs Addeing by Business/Events
   
   public contacts_added_detalis         = new BehaviorSubject<Object>(Object);
   
   // Documents 
   
    public documents_root                 = new BehaviorSubject<Object>(Object);
	
	public documents_types                = new BehaviorSubject<Object>(Object);
	
	public documents_types_filter         = new BehaviorSubject<Object>(Object);
	
	public documents_floders_list         = new BehaviorSubject<Object>(Object);
	
	public documents_parent_list          = new BehaviorSubject<Object>(Object);
	
	public meta_tag_titles                = new BehaviorSubject<Object>(Object);
	
   public subscription_listing_screen     = new BehaviorSubject<Object>(Object);

  public subscription_listing_defscreen   = new BehaviorSubject<Object>(Object);
  
  public subscription_current_purchase    = new BehaviorSubject<Object>(Object);
  
  public subscription_current_balance     = new BehaviorSubject<Object>(Object);

  public reload_subscription              = new BehaviorSubject<Object>(Object);
  
  private headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8;', 'Authorization': 'Basic ' + btoa(this._username + ':' + this._password) });
  private headers_multi = new HttpHeaders({ 'Content-Type': 'multipart/form-data'});
  constructor(private httpClient: HttpClient, private toastr: ToastrService) { }
  public getbaseurl() {

    return this.apiURL;
  }
  public get_apiurl() {

    return this.BaseURL;
  }
  public get_weburl() {
    return this.webURL;
 }
  public getrequestheader() {
    return this.headers;
  }
  public setNewUserRegisterDetails(user: Object) {
    this.newUserDetails = user;
	if( localStorage.getItem('trouble_current_user' ) != null ) {
             localStorage.removeItem("trouble_current_user");	 
	 }
	 localStorage.setItem('trouble_current_user',this.newUserDetails.user_mobile);
  }
  public getNewUserRegisterDetails() {
    return this.newUserDetails;
  }
  public setCurrentUserDetails(user: Object) {
    this.currentUSerDetails = user;	
	
  }
  public getCurrentUserDetails() {
	   if ( localStorage.getItem( 'currentUser' ) != null ) {
            return JSON.parse( localStorage.getItem( 'currentUser' ) );
		}
        // return this.currentUSerDetails;
  }
 // Account Settings usertype not filled opening in accounts settings popup 
 accountSettings_login(obj:any)
 {
   this.account_settings.next(obj)
 }
  
  public getOtherUserDetails() {
	   if ( localStorage.getItem( 'OtherUser' ) != null ) {
            return JSON.parse( localStorage.getItem( 'OtherUser' ) );
		}
        // return this.currentUSerDetails;
  }
  public get(url: string) {
    return this.httpClient.get(environment.base_url + url);
  }
  public post(url: string, obj: any,) {
    /* return this.httpClient
      .post(environment.base_url + url, obj).pipe(map((response: any) => response.json())); */
    return this.httpClient
      .post(environment.base_url + url, obj).pipe(
        map((response) => {
          // some response manipulation
          let result = response;
          return result;
        }));
  }  
  public post1(url: string, data: any) {
	  const HttpUploadOptions = { headers: new HttpHeaders({ "Content-Type": "multipart/form-data;"})};
	  
    return this.httpClient
      .post(environment.base_url + url, data,HttpUploadOptions).pipe(
        map((response) => {
          // some response manipulation
          let result = response; 
          return result;
        }));
  }
  
   // Event Records Fetching  
   public ger_Current_EventDetails() {
	   if ( localStorage.getItem( 'Event_Records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'Event_Records' ) );
		}
   }

   public ger_Current_Event_SubeventDetails() {
	   if ( localStorage.getItem( 'SubEvent_Records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'SubEvent_Records' ) );
		}
   }
   
      // Invite USer Records Fetching
  public ger_User_Invite_EventDetails() {
	   if ( localStorage.getItem( 'invite_user_records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'invite_user_records' ) );
		}
        // return this.user_invited_event;
   }  
   // Get user fetching screens
  public get_userfetching_screenDetails() {
	   if ( localStorage.getItem( 'listing_screen_records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'listing_screen_records' ) );
		}
        // return this.user_invited_event;
   }
      // Get Recycle Binuser fetching screens
   public get_recyclefetching_screenDetails() {
	   if ( localStorage.getItem( 'recycle_screen_records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'recycle_screen_records' ) );
		}
   }
   public get_conatcts_catDetails() {
	   if ( localStorage.getItem( 'contact_type_records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'contact_type_records' ) );
		}
   }
    // Get Subscription user fetching screens
   public get_subscriptionfetching_screenDetails() {
    if ( localStorage.getItem( 'subscription_screen_records' ) != null ) {
           return JSON.parse( localStorage.getItem( 'subscription_screen_records' ) );
   }
  }  

  // Get Subscription user fetching screens
   public get_subscriptionfetching_planDetails() {
    if ( localStorage.getItem( 'subscription_current_plansdetalis' ) != null ) {
           return JSON.parse( localStorage.getItem( 'subscription_current_plansdetalis' ) );
   }
  }    


 imgage_upload_type(obj:any) {
   this.img_upload_type.next(obj)
 }
   
  
  showSuccess(message, title) {
    this.toastr.success(message, title)
  }
  showSuccessWithTimeout(message, title, timespan) {
    this.toastr.success(message, title, {
      timeOut: timespan
    })
  }
  showError(message, title) {
    this.toastr.error(message, title);
  }
  showErrorWithTimeout(message, title, timespan) {
    this.toastr.error(message, title, {
      timeOut: timespan
    })
  }
  showInfo(message, title) {
    this.toastr.info(message, title);
  }
  showInfoWithTimeout(message, title, timespan) {
    this.toastr.info(message, title, {
      timeOut: timespan
    })
  }
  showWarning(message, title) {
    this.toastr.warning(message, title);
  }
  showWarningWithTimeout(message, title, timespan) {
    this.toastr.warning(message, title, {
      timeOut: timespan
    })
  }
  user_personal_records(obj:any) {
    this.user_records.next(obj)
  }
   // Contact List Records
  user_contacts_records(obj: Object) {
	   if ( localStorage.getItem( 'OtherUser' ) != null ) {
             localStorage.removeItem("OtherUser");	 
		}
	  localStorage.setItem( 'OtherUser', JSON.stringify( obj ) );
      this.user_personal_detalis.next(obj );
  }
   
   //show add_icon in dashboard
  user_profile(obj:any) 
  {
    this.edit_user_profile.next(obj)
  } 
  //documents types fliter options
  document_filterdetalis(obj:any) 
  {
    this.documents_types_filter.next(obj)
  }
  document_rootdetalis(obj:any) 
  {
    this.documents_root.next(obj)
  }
  document_typedetalis(obj: Object) 
  {
     if( localStorage.getItem( 'Documents_Records' ) != null ) {
             localStorage.removeItem("Documents_Records");	 
	 }
	localStorage.setItem( 'Documents_Records', JSON.stringify( obj ) );
    this.documents_types.next(obj)
  }
  documents_floders_list_records(obj:any) 
  {
    this.documents_floders_list.next(obj)
  }
  getdocuments_records() {
       if ( localStorage.getItem( 'Documents_Records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'Documents_Records' ) );
	   }
  }
  
  parentdcoument_recordsdetalis(obj: Object)
  {
	   if( localStorage.getItem( 'Documents_Root_Records' ) != null ) {
             localStorage.removeItem("Documents_Root_Records");	 
	 }
	  localStorage.setItem( 'Documents_Root_Records', JSON.stringify( obj ) );
      this.documents_parent_list.next(obj)
  }
  
   get_parent_documents_records() {
       if ( localStorage.getItem( 'Documents_Root_Records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'Documents_Root_Records' ) );
	   }
  }
  
  
   // Event Records Maintaining
   user_events_records(obj: Object)
   {
	   if ( localStorage.getItem( 'Event_Records' ) != null ) {
             localStorage.removeItem("Event_Records");	 
		}
	  localStorage.setItem( 'Event_Records', JSON.stringify( obj ) );
      this.user_eventrecords.next(obj );
   }  
   main_events_detalis(obj: Object)
   {
      this.main_eventrecords.next(obj);
   }  
   sub_events_detalis(obj: Object)
   {
      this.sub_eventrecords.next(obj);
   }  
   
   user_subevents_records(obj: Object)
   {
	   if ( localStorage.getItem( 'SubEvent_Records' ) != null ) {
             localStorage.removeItem("SubEvent_Records");	 
		}
	  localStorage.setItem( 'SubEvent_Records', JSON.stringify( obj ) );
      this.user_subeventrecords.next(obj );
   }
   user_created_eventsrecords(obj:any) {
     this.events_personal_records.next(obj)
  }
  user_jointme_eventsrecords(obj:any) {
     this.events_jointme_records.next(obj)
  }
   user_events_dateupdated_records(obj:any) {
     this.events_date_records.next(obj)
  }   
   user_subevents_dateupdated_records(obj:any) {
     this.subevents_view_records.next(obj)
  }
   
  //Invite User Event
   user_invite_event(obj: Object)
   {
	   console.log(obj);
	    if( localStorage.getItem( 'invite_user_records' ) != null )
		{
            localStorage.removeItem("invite_user_records");	 
		}
		localStorage.setItem( 'invite_user_records', JSON.stringify( obj ) );
		this.user_invited_event.next(obj);
   }
  
  //Other User View Records 
  othe_user_records(obj:any) {
     this.other_user_detalis.next(obj)
  }
   //Classfication Events Received Records 
  event_received_classficationrecords(obj: Object) {
     this.events_received_classfication.next(obj)
  }
  
   //Classfication Events Received Records 
  event_new_received_classficationrecords(obj: Object)
   {
	   this.events_received_classfication1.next(obj);
   }
   
   // Event Received Event Notification Counts Detalis
   
   events_overall_notifications_records(obj : Object)
   {
	   this.events_received_notifications_records.next(obj);
   }
  
  //Show Checkbox in Subevent List And Share Options
   confirm_share_subevents(obj: Object)
   {
	   this.share_allevents.next(obj);
   }
   share_subevents_groups_records(obj: Object)
   {
	   this.share_subevent_source.next(obj);
   }
   shared_subevents_records(obj: Object)
   {
	   this.shared_subevents_source.next(obj);
   }
   shared_discard_options(obj: Object)
   {
     this.share_events_records.next(obj);
   }
   
   recylebin_user_records(obj: Object)
   {
	   this.restore_userdetalis.next(obj);
   } 
   restorerecycle_user_records(obj: Object)
   {
	   this.restore_recycledata.next(obj);
   }
   restored_user_records(obj: Object)
   {
	   this.restored_datas.next(obj);
   }
   set_metatag_titles(obj: Object)
   {
       this.meta_tag_titles.next(obj);
   }
   //Events Listing Screen Maintaining
   events_listing_screenrecords(obj: Object)
   {
	   if ( localStorage.getItem( 'listing_screen_records' ) != null ) {
             localStorage.removeItem("listing_screen_records");	 
		}
	  localStorage.setItem( 'listing_screen_records', JSON.stringify( obj ) );
	  
	   this.events_listing_screen.next(obj);
   }
   events_listing_defaultscreenrecords(obj: Object)
   {
	   this.events_listing_defscreen.next(obj);
   }
   // Recycle Bin Screen Maintaining
   recycle_listing_screenrecords(obj: Object)
   {
	   if ( localStorage.getItem( 'recycle_screen_records' ) != null ) {
             localStorage.removeItem("recycle_screen_records");	 
		}
	  localStorage.setItem('recycle_screen_records', JSON.stringify( obj ) );
	  
	   this.recycle_listing_screen.next(obj);
   } 
   recycle_listing_defaultscreenrecords(obj: Object)
   {
	   this.recycle_listing_defscreen.next(obj);
   }    
   // Contacts Added in By Business/Events Based
   contact_added_catrecords(obj: Object)
   {
	   if ( localStorage.getItem( 'contact_type_records' ) != null ) {
             localStorage.removeItem("contact_type_records");	 
		}
	  localStorage.setItem('contact_type_records', JSON.stringify( obj ) );
	  
	   this.contacts_added_detalis.next(obj); 
   } 
  // Subscription Screen Maintaining
   subscription_listing_screenrecords(obj: Object)
   {
	   if ( localStorage.getItem( 'subscription_screen_records' ) != null ) {
             localStorage.removeItem("subscription_screen_records");	 
		}
	  localStorage.setItem('subscription_screen_records', JSON.stringify( obj ) );
	  
	   this.subscription_listing_screen.next(obj);
   } 
  //
    
   subscription_current_plans(obj: Object)
   {
	   if ( localStorage.getItem( 'subscription_current_plansdetalis' ) != null ) {
             localStorage.removeItem("subscription_current_plansdetalis");	 
		}
	  localStorage.setItem('subscription_current_plansdetalis', JSON.stringify( obj ) );
	  
	   this.subscription_current_purchase.next(obj);
   } 

   subscription_listing_defaultscreenrecords(obj: Object)
   {
	   this.subscription_listing_defscreen.next(obj);
   }   
   subscription_user_balance(obj: Object)
   {
	   this.subscription_current_balance.next(obj);
   }  
    //Get File Uploaded Api Records
	 getUploadedFileSize(user_mobile): any {
		   return this.httpClient.get(environment.base_url + 'read_commonsettings');
    }
	user_fileuploaded_records(obj: Object)
   {
	   if ( localStorage.getItem( 'fileuploaded_records' ) != null ) {
             localStorage.removeItem("fileuploaded_records");	 
		}
	  localStorage.setItem('fileuploaded_records', JSON.stringify( obj ) );
	  this.chatconversation_userdetalis.next([obj]);
   }
     public getFileRecords() {
	   if ( localStorage.getItem( 'fileuploaded_records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'fileuploaded_records' ) );
		}
        // return this.currentUSerDetails;
   }
        // Contact List Records
  reload_user_subscripiton_details(obj:any) 
  {
    this.reload_subscription.next(obj);
  } 
  
  // Chat Conversation Records
  
  	user_chatconversations_records(obj:any)
   {
	   if (localStorage.getItem( 'chatconversation_records' ) != null ) {
             localStorage.removeItem("chatconversation_records");	 
		}
	   localStorage.setItem('chatconversation_records', JSON.stringify( obj ));
	   this.chatconversation_userdetalis.next([obj]);
   }
   
   public getChat_ConversationsRecords() {
	   if ( localStorage.getItem( 'chatconversation_records' ) != null ) {
            return JSON.parse( localStorage.getItem( 'chatconversation_records' ) );
		}
   }
  
  
  
}
