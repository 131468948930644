import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonapiService } from '../../shared/services/commonapi.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AboutusService {

 constructor( private commonService: CommonapiService, private httpClient: HttpClient) { }

// GET Aboutsus
	getAboutusrecords() {
    return this.commonService.get('readall_contentmangament_detalis');
  }
 

}
