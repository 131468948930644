import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TermsService } from './terms.service';
import { CommonapiService } from '../../shared/services/commonapi.service';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})

export class TermsComponent implements OnInit {
  accessData;
  aboutus_list:any =[];
  title:string ="";
  content_name:string ="";
  content_description:string ="";
  constructor(private service: TermsService, private router: Router,private commonservice: CommonapiService)
  {
  }

  ngOnInit() {
		this.get_termsrecords();
  }
  
  get_termsrecords()
  {
	  this.service.getTermsrecords().subscribe(( response ) => {
			if (!response['error']) 
			{
				let result              = response;
				this.aboutus_list       = result['content_management'];
				if(this.aboutus_list.length >0)
				{
					this.title                       = this.aboutus_list[0].title;
					this.content_description          = this.aboutus_list[0].content_description;
				}
			}
		},(err: HttpErrorResponse) => 
			{
				if (err.error instanceof Error) 
				{
					console.log('Client-side error occured.');
				} 
				else 
				{
					console.log('Server-side error occured.');
				}
		   });
  }

}
