// httpSetHeaders.interceptor.ts
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonapiService } from '../shared/services/commonapi.service';
import { Router } from '@angular/router'; 
import { Location } from '@angular/common'; 
@Injectable()
export class httpSetHeaders implements HttpInterceptor {
    private _username: string = 'lmx@ido';
    private _password: string = 'lmx@2020';
    constructor(private location: Location,private router:Router,private commonservice: CommonapiService) 
	{
		
	}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        // request = request.clone({ headers: request.headers.set(this._username, this._password) });
        request = request.clone({ headers: request.headers.set('Authorization', 'Basic ' + btoa(this._username + ':' + this._password)) });
        //private headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8;', 'Authorization': 'Basic ' + btoa(this._username + ':' + this._password) });
        const started = Date.now();
        let ok: string;
        
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event', event);
                    ok = 'succeeded';
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                ok = 'failed';
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };

                return throwError(error);
            }));
    }
}
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private location: Location,private router:Router,private commonService: CommonapiService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.commonService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.requests.push(req);
        console.log("No of requests--->" + this.requests.length);
        this.commonService.isLoading.next(true);
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
							if(typeof event.body.error !=='undefined')
						    {
							
								if(typeof event.body.session !=='undefined')
								{
									if(event.body.session == 1)
									{
										this.commonService.showError(event.body.message,'Session Termination');
										localStorage.removeItem('isLoggedin');
										localStorage.removeItem('currentUser');
										localStorage.removeItem('show_icon');
										localStorage.clear();
										this.router.navigate(['/login']);
									}
								}
								
							}
                        }
                    },
                    err => {
                        this.commonService.showError(err.message,'Service Call');
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}