declare const Pusher: any;
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
@Injectable({ 
  providedIn: 'root'
})

export class PusherService {
  pusher: any;
  messagesChannel: any;

  constructor() {
    this.initializePusher();
  }

  initializePusher(): void {
  /*   this.pusher           = new Pusher(environment.pusher.key,{cluster:'ap2',forceTLS:true});
    this.messagesChannel  = this.pusher.subscribe('my-channel'); */
	/* this.messagesChanne.bind('my-event', function(data) {
      alert(JSON.stringify(data));
    }); */
	
	/*Pusher.logToConsole = true;

    var pusher = new Pusher('4b252d0db3f087962570', {
      cluster: 'ap2',
      forceTLS: true
    });

    var channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function(data) {
      alert(JSON.stringify(data));
    }); */
	
  }
}
