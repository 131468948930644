import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CommonapiService {
  apiURL: string = "http://localhost:8000/api/v1/";
  /*   private _username: string = 'lmx@ido';
    private _password: string = 'lmx@2020'; */
  private _username: string = 'lmx@winbull';
  private _password: string = 'lmx@2018';
  public UserName: string;
  public isLoading = new BehaviorSubject(false);
  public newUserDetails: any = {};
  public currentUSerDetails: any = {};

  private headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8;', 'Authorization': 'Basic ' + btoa(this._username + ':' + this._password) });
  constructor(private httpClient: HttpClient) { }
  public getbaseurl() {

    return this.apiURL;
  }
  public getrequestheader() {
    return this.headers;
  }
  public setNewUserRegisterDetails(user: Object) {
    this.newUserDetails = user;
  }
  public getNewUserRegisterDetails() {
    return this.newUserDetails;
  }
  public setCurrentUserDetails(user: Object) {
    this.currentUSerDetails = user;
  }
  public getCurrentUserDetails() {
    return this.currentUSerDetails;
  }
  public get(url: string) {
    return this.httpClient.get(environment.base_url + url);
  }
  public post(url: string, obj: any) {
    /* return this.httpClient
      .post(environment.base_url + url, obj).pipe(map((response: any) => response.json())); */
    return this.httpClient
      .post(environment.base_url + url, obj).pipe(
        map((response) => {
          // some response manipulation
          let result = response;
          return result;
        }));
  }
}
