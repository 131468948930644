import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { PolicyService } from './policy.service';
import { CommonapiService } from '../../shared/services/commonapi.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})

export class PolicyComponent implements OnInit {
  accessData;
  aboutus_list:any =[];
  title:string ="";
  content_name:string ="";
  content_description:string ="";
  constructor(private service: PolicyService, private router: Router,private commonservice: CommonapiService)
  {
  }

  ngOnInit() {
		this.get_privacypolicyrecords();
  }
  
  get_privacypolicyrecords()
  {
	  this.service.getPrivacyPolicyrecords().subscribe(( response ) => {
			if (!response['error']) 
			{
				let result              = response;
				this.aboutus_list       = result['content_management'];
				if(this.aboutus_list.length >0)
				{
					this.title                       = this.aboutus_list[0].title;
					this.content_description          = this.aboutus_list[0].content_description;
				}
			}
		},(err: HttpErrorResponse) => 
			{
				if (err.error instanceof Error) 
				{
					console.log('Client-side error occured.');
				} 
				else 
				{
					console.log('Server-side error occured.');
				}
		   });
  }

}

