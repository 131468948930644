import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import {
   MatSidenavModule,
   MatFormFieldModule, 
} from '@angular/material';
import { DatePipe } from '@angular/common';  //  Use The Date Pipe For Date Converter
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { httpSetHeaders, LoaderInterceptor } from './interceptor/httpSetHeaders.interceptor';
import { LoaderComponent } from './shared/common/loader/loader.component';
import { CommonapiService } from './shared/services/commonapi.service';
import { PusherService } from './shared/services/pusher.service';
import { WindowRefService } from './shared/services/window-ref.service';
import { MessageService } from './shared/services/message.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileSelectDirective,FileDropDirective ,FileUploadModule} from "ng2-file-upload";
//Footers
import { AboutusComponent } from './footersnew/aboutus/aboutus.component';
import { WorksComponent } from './footersnew/works/works.component';
import { PolicyComponent } from './footersnew/policy/policy.component';
import { TermsComponent } from './footersnew/terms/terms.component';
import { ContactusComponent } from './footersnew/contactus/contactus.component'; 

// Services
import { AboutusService } from './footersnew/aboutus/aboutus.service';


import {MatButtonModule, MatCardModule, MatIconModule, MatTableModule,MatCheckboxModule, MatInputModule,MatToolbarModule,MatMenuModule,MatAutocompleteModule,MatTooltipModule,MatDialogModule,MatGridListModule,MatListModule} from '@angular/material';
// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-javascript/sb-admin-material/master/dist/assets/i18n/',
        '.json'
    );*/
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
    declarations: [AppComponent, UserRegisterComponent, LoaderComponent,AboutusComponent,WorksComponent,PolicyComponent, 
	TermsComponent,ContactusComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        ToastrModule.forRoot({ 
		timeOut: 3000,
        preventDuplicates: true,
       }),
		MatButtonModule,
		MatIconModule,
		MatListModule,
		MatSidenavModule,
		MatToolbarModule,
		MatCardModule,
		MatFormFieldModule,
		MatDialogModule,
		MatTooltipModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
	exports:[MatTooltipModule],
	entryComponents: [],
    providers: [CommonapiService,PusherService,MessageService,DatePipe,{ provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },{provide: LocationStrategy, useClass: HashLocationStrategy},
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },AboutusService],
    bootstrap: [AppComponent]
})
export class AppModule { }
