import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { AboutusComponent } from './footersnew/aboutus/aboutus.component';
import { WorksComponent } from './footersnew/works/works.component';
import { PolicyComponent } from './footersnew/policy/policy.component';
import { TermsComponent } from './footersnew/terms/terms.component';
import { ContactusComponent } from './footersnew/contactus/contactus.component'; 
const routes: Routes = [
    {
        path: '',
        loadChildren: './layout/layout.module#LayoutModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'userregister',
        loadChildren: './user-register/user-register.module#UserRegisterModule'
    },
	 {
        path: 'trouble_login',
        loadChildren: './forget_password/forget_password.module#Forget_PasswordModule'
    },
    { 
		path: 'screen3/:id', 
		loadChildren: './layout/screen3/screen3.module#Screen3Module'
     },
	 { 
         path: 'share_userprofilepage/:id/:id1',
         loadChildren:'./layout/share_userprofilepage/share_userprofilepage.module#Share_UserprofilePageModule'
     },
	 {
		 path: 'sharedevents/:id',
	     loadChildren: './layout/sharedevents/events.module#EventsModule'
     },
	 {
        path: 'about_us',
        component: AboutusComponent
     },
	   {
        path: 'how_it_works',
        component: WorksComponent
     },
     {
        path: 'term&conditions',
        component: TermsComponent
     },
     {
        path: 'privacy&policy',
        component: PolicyComponent
     },
     {
        path: 'contact_us',
        component: ContactusComponent
     } 
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {}
