import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta,Title } from '@angular/platform-browser';
import { CommonapiService } from './shared/services/commonapi.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	
	 title = 'IDO - User Login Page';
    constructor(private translate: TranslateService,private titleService: Title,private meta: Meta,private commonservice: CommonapiService,) {
			this.commonservice.set_metatag_titles([]);
        translate.setDefaultLang('en');
    }

    ngOnInit() {
		
						 //	USER SEARCHING CONTACTS NAME CITY TYPE BASED
				 
		this.commonservice.meta_tag_titles.subscribe((meta_records: any[]) => {
			 if(meta_records.length > 0)
			 {
				this.title                 = meta_records[0].title;
				this.titleService.setTitle(this.title);	
			 }
		  });  
       	  
    }
}
