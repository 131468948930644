import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { WorksService } from './works.service';
import { CommonapiService } from '../../shared/services/commonapi.service';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {
  accessData;
  aboutus_list:any =[];
  title:string ="";
  content_name:string ="";
  content_description:string ="";
  constructor(private service: WorksService, private router: Router,private commonservice: CommonapiService)
  {
  }

  ngOnInit() {
	  // CURRENT USER DETAILS
		this.get_aboutusrecords();
  }
  
  get_aboutusrecords()
  {
	  this.service.getWorksrecords().subscribe(( response ) => {
			if (!response['error']) 
			{
				let result              = response;
				this.aboutus_list       = result['content_management'];
				if(this.aboutus_list.length >0)
				{
					this.title                       = this.aboutus_list[0].title;
					this.content_description          = this.aboutus_list[0].content_description;
				}
			}
		},(err: HttpErrorResponse) => 
			{
				if (err.error instanceof Error) 
				{
					console.log('Client-side error occured.');
				} 
				else 
				{
					console.log('Server-side error occured.');
				}
		   });
  }
}
