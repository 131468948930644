import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PusherService } from './pusher.service';

export interface Message {
  text: string;
  user: string;
}

@Injectable()
export class MessageService {
  messagesStream = new ReplaySubject<Message>(1); 

  constructor(private pusherService: PusherService) {
    this.initialize();
  }

  initialize() {
   /*  this.pusherService.messagesChannel.bind('client-new-message', (message) => {
      this.emitNewMessage(message);
    }); */
  }

  send(message: Message) {
    this.pusherService.messagesChannel.trigger('client-new-message', message);
    this.emitNewMessage(message);
  }

  emitNewMessage(message: Message) {
    this.messagesStream.next(message);
  }

}
